import { baseYearRange } from "./baseTypes";
const base = {
  collection: "data_11_2023_pro",
  database: "testdb",
  dataSource: "Cluster0",
};

const byDestination = {
  ...base,
  pipeline: [
    {
      $group: {
        _id: "$region_name",
        TOTAL: {
          $sum: "$usd_disbursement",
        },
      },
    },
  ],
};
const recipientbyname = {
  ...base,
  pipeline: [
    {
      $match: {
        recipient_name: "Thailand",
      },
    },
    {
      $group: {
        _id: "$donor_name",
        Total: {
          $sum: "$usd_disbursement",
        },
      },
    },
  ],
};

const allRecipientsByCategory = {
  ...base,
  pipeline: [
    {
      $group: {
        _id: ["$recipient_name", "$Recategorisation"],
        TOTAL: {
          $sum: "$usd_disbursement",
        },
      },
    },
  ],
};

const getAllRecipientsByRegion = (years) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          region_sp: { $ne: "NA" },
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: ["$region_sp", "$Recategorisation"],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllDonorsByCategory = (years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          region_sp: { $ne: "NA" },
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: ["$donor_name", "$Recategorisation"],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};
// monbodb table contains a property called donor_name. It is a string. Write a query to get all the different values of donor_name
const allDonorNames = {
  ...base,
  pipeline: [
    {
      $group: {
        _id: "$donor_name",
        iso: {
          $first: "$donor_iso_code",
        },
      },
    },
  ],
};

const allRecipientNames = {
  ...base,
  pipeline: [
    {
      $group: {
        _id: "$recipient_name",
        _code: {
          $first: "$recipient_code",
        },
      },
    },
  ],
};
const allCategories = {
  ...base,
  pipeline: [
    {
      $group: {
        _id: "$Recategorisation",
      },
    },
  ],
};

const getAllCountriesByCountryTotal = (years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          recipient_iso_code: { $ne: "NA" },
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$recipient_iso_code",
          Total: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};
const getAllRegionsByRegionTotal = (years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          recipient_iso_code:  "NA",
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$recipient_code",
          Total: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};


const getAllRecipientsByCategoryFilterByDonor = (donorIsoCode, years) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_iso_code: donorIsoCode,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: ["$recipient_code", "$Recategorisation"],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllDonorsByCategoryFilterByRecipient = (donorName, years) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          recipient_code: donorName,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: ["$donor_iso_code", "$Recategorisation"],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllCountriesByCountryOneDonor = (donorName, years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_name: donorName,
          recipient_iso_code: { $ne: "NA" },
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$recipient_iso_code",
          Total: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllReceipientsOneDonor = (donorName) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_name: donorName,
        },
      },
      {
        $group: {
          _id: "$recipient_name",
          Total: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllCategoriesOneDonor = (donorIsoCode, years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_iso_code: donorIsoCode,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$Recategorisation",
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllCategoriesOneRecipient = (recipientCode, years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          recipient_code: recipientCode,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$Recategorisation",
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllCategoriesOneRecipientOneDonor = (recipientCode,donorIsoCode, years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_iso_code: donorIsoCode,
          recipient_code: recipientCode,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$Recategorisation",
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getTotalByYearCategoryOneDonor = (donorIsoCode) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_iso_code: donorIsoCode,
        },
      },
      {
        $group: {
          _id: ["$year", "$Recategorisation"],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getTotalByYearCategoryManyRecipients = (recipient_codes) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {          
          recipient_code: {
            $in: recipient_codes
        }
        },
      },
      {
        $group: {
          _id: ["$recipient_code", "$year" ],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};


const getTotalByYearCategoryOneRecipient = (recipient_code) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          recipient_code: recipient_code,
        },
      },
      {
        $group: {
          _id: ["$year", "$Recategorisation"],
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllCategoriesByDonor = (donorIsoCode,years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          donor_iso_code: donorIsoCode,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$aid_t",
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllCategoriesByRecipient = (recipientCode,years) => {
  if (years == undefined) years = baseYearRange
  return {
    ...base,
    pipeline: [
      {
        $match: {
          recipient_code: recipientCode,
          year: { $gte: years[0], $lte: years[1] },
        },
      },
      {
        $group: {
          _id: "$aid_t",
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};

const getAllRecipientsValuesOneRegion = (regionName) => {
  return {
    ...base,
    pipeline: [
      {
        $match: {
          region_sp: regionName,
        },
      },
      {
        $group: {
          _id: "$recipient_name",
          TOTAL: {
            $sum: "$usd_disbursement",
          },
        },
      },
    ],
  };
};
// export data structures:
export const queries = {
  byDestination,
  recipientbyname,
  allDonorNames,
  allRecipientNames,
  allCategories,
  allRecipientsByCategory,
  getAllRecipientsByRegion,
  getAllDonorsByCategory,
  getAllCountriesByCountryTotal,  
  getAllRegionsByRegionTotal,
  getAllCountriesByCountryOneDonor,
  getAllReceipientsOneDonor,
  getAllCategoriesOneRecipient,
  getAllCategoriesOneDonor,
  getAllCategoriesOneRecipientOneDonor,
  getAllRecipientsByCategoryFilterByDonor,
  getAllDonorsByCategoryFilterByRecipient,
  getTotalByYearCategoryOneDonor,
  getTotalByYearCategoryOneRecipient,
  getAllCategoriesByDonor,
  getAllCategoriesByRecipient,
  getAllRecipientsValuesOneRegion,

  getTotalByYearCategoryManyRecipients
};
