import { defineStore } from "pinia";
import { queries } from "@/assets/queries.js";
import { json } from "d3";
//import recipients from "@/assets/recipients.json";

// read about cache here:
// https://stackoverflow.com/questions/51121690/best-practice-for-fetching-data-from-api-using-vuex




import * as donors from "../assets/data/donors.json";
import * as recipients from "../assets/data/recipients.json";
import * as categories from "../assets/data/categories.json";
donors.default.sort((a,b)=> a._id.localeCompare(b._id))
recipients.default.sort((a,b)=> a._id.localeCompare(b._id))
export const useBaseDataStore = defineStore("baseData", {
  state: () => ({
    isLoading: ref(false),
    isError: ref(false),
    errorMsg: ref(null),
    data: ref(null),
    clientToken: ref(null),
    refreshToken: ref(null),
    lastTimeRefreshed: ref(null),
    ssrToken: ref(null),
    allDonorNames: ref(donors.default),
    allRecipientNames: ref(recipients.default),
    allCategories: ref(categories.default),
    url:  useRuntimeConfig().public.apiURL
  }),
  getters: {
    httpConfig(state) {
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          Authorization: "Bearer " + this.token,
        },
      };
    },
    baseDataLoaded(state) {
      return (
        state.allDonorNames.length > 0 &&
        state.allRecipientNames.length > 0 &&
        state.allCategories.length > 0
      );
    },
    token(state) {
      if(process.server) return state.ssrToken
      else return state.clientToken
    },
    hasToken(state) {
      return state.clientToken !== null;
    },    
  },
  actions: {
    getHttpConfig(bodydata) {
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          Authorization: "Bearer " + this.token,
        },
        body: bodydata,
      };
    },
    // caller function must act on error:
    async getNewTokenFromRefresh(refreshToken, force){
      if(force === undefined) force = false
      // if token was refreshed less than 25 minutes ago, don't refresh again
      if (this.lastTimeRefreshed !== null){
        const now = new Date();
        const diff = now - this.lastTimeRefreshed;
        if (diff < 25 * 60 * 1000 && force===false && this.clientToken!=null) return Promise.resolve();
       // if (diff < 31* 1000) return Promise.resolve();
      }
      // else refresh
      const response = await $fetch(useRuntimeConfig().public.refreshTokenURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + refreshToken,
        },
      })
      .catch((err) => {        
        console.log(err)
        console.log('catcheed error refreshing token')
        return Promise.reject(new Error(400));        
      });
      const data = await response
      if(data.access_token){
        this.clientToken = data.access_token;
        this.lastTimeRefreshed = new Date();
        // console.log("refresh: from client");
      }
      else 
        this.clientToken=null
      return response;  
    },
    async fetchAuthKey(){
      await Promise.all([this.fetchAuthKeyClient(true), this.fetchAuthKeyServer()])
    },
    async fetchAuthKeyClient(force) {
      if(process.server) return Promise.resolve()
      if (force === undefined) force = false;
      if (this.clientToken !== null && force === false) {
        return Promise.resolve();
      }     
      const response = await fetch(useRuntimeConfig().public.authURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      this.clientToken = data.access_token;
      this.refreshToken = data.refresh_token;
      // console.log("token: from client" + this.clientToken.slice(-10));
      return response;      
    },
    async fetchAuthKeyServer(){     
      if (this.ssrToken !== null) {
        console.log("server token already exists");
        return Promise.resolve();
      }    
      const { data: data1 } = await useFetch("/api/ssrtoken");
      this.ssrToken = data1.value.access_token;
      // console.log('got token from server', this.ssrToken.slice(-10) )
      return Promise.resolve(data1);
    },
    resetToken() {
      this.clientToken = null;
    },

    pullAllBaseData(force) {
      if (force === undefined) force = false;
      if (this.baseDataLoaded && !force) return;
      // usually base data is loaded from json files. This is just in case
      this._pullAllDonorNames();
      this._pullAllRecipientNames();
      this._pullAllCategories();
    },

    _pullAllDonorNames() {
      let prom = null;
      try {
        prom = $fetch(this.url, this.getHttpConfig(queries.allDonorNames)).then(
          (res) => {
            this.allDonorNames = res.documents;
          }
        );
      } catch (error) {
        console.log(error);
      }
      return prom;
    },
    _pullAllRecipientNames() {
      let prom = null;

      try {
        prom = $fetch(this.url, this.getHttpConfig(queries.allRecipientNames)).then(
          (res) => {
            this.allRecipientNames = res.documents;
          }
        );
      } catch (error) {
        console.log(error);
      }
      return prom;
    },
    _pullAllCategories() {
      let prom = null;
      try {
        prom = $fetch(this.url, this.getHttpConfig(queries.allCategories)).then(
          (res) => {
            this.allCategories = res.documents;
          }
        );
      } catch (error) {
        console.log(error);
      }
      return prom;
    },
  },
});
