import { _Categories, _Scale } from '#tailwind-config/theme/colors'
const categoriesArray= Object.values(_Categories)
import categorynames from './categories.json'
export const _Categories2Array= ["#333","#262E6A", "#7D82A6","#D62728","#E67D7E"]
// const _Categories2Array= ["#e26475", "#efd668", "#5ec66d", "#cd66ec", "#a6c0bc", "#646ee5", "#faacb4", "#56c6de", "#ab8c7a", "#cbe649", "#b2a1d5"]

import {scaleOrdinal, scaleLinear} from 'd3-scale'
export const httpConfig = (data,token) => {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        Authorization: "Bearer " + token,
      },
      body: data,
    };
  };

// create a d3 color scale that maps the category to a color
// this is the list of categories:
// justice and the rule of law, civil society support, political inclusion, media support, political participation, civic and political education, political party support, election observation & follow-up, parliamentary strengthening, transparency & anticorruption, civil and political rights, electoral assistance
// colors are in tailwind.config.js under theme.colors
export const categoryColorScale = scaleOrdinal()
  .domain(categorynames.map((d) => d.name))
  .range(categoriesArray);
 
const categoriesTextColor = ['black','black','black','white','black','white','black','white','black','black','black','black']

export const categoryTextColorScale = scaleOrdinal().domain(categorynames.map((d) => d.name)).range(categoriesTextColor)

// create a linear Color scale between the first and last color of the _Scale array
export const linearColorScale = scaleLinear()
  .domain([0, 1])
  .range([_Scale['50'], _Scale['500']]);

export const categoryColorScale2 = scaleOrdinal().range(_Categories2Array)

export const baseYearRange = [2014, 2020]



